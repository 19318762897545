import { SliceMap } from 'types';
import ImageTextBlock from 'features/v2/ImageTextSlice';
import Breadcrumbs from 'features/Breadcrumbs';
import { Spacing } from 'features/Spacing/Spacing';
import Hero from 'features/Hero';
import FooterBanner from 'features/FooterBanner';
import { Hero as Herov2 } from 'features/v2/Hero';
import { HeroFeatures as HeroFeaturesv2 } from 'features/v2/HeroFeatures';
import { HeroLogos as HeroLogosv2 } from 'features/v2/HeroLogos';
import { PrismicImageTabs, PrismicTextGrid } from '@smartproxy-web/features';

export enum CONTACT_US_SLICES {
  Hero = 'hero_block',
  Spacing = 'spacing',
  TextGrid = 'text_grid',
  Breadcrumbs = 'breadcrumbs',
  ImageTextBlock = 'image_text_block',
  FooterBanner = 'footer_banner_slice',
  Herov2 = 'hero_v2',
  HeroFeaturesv2 = 'hero_v2_features',
  HeroLogosv2 = 'hero_v2_logos',
  ImageTabs = 'image_tabs',
}

export const contactUsSliceMap: SliceMap<CONTACT_US_SLICES> = {
  [CONTACT_US_SLICES.Hero]: Hero,
  [CONTACT_US_SLICES.Spacing]: Spacing,
  [CONTACT_US_SLICES.TextGrid]: PrismicTextGrid,
  [CONTACT_US_SLICES.Breadcrumbs]: Breadcrumbs,
  [CONTACT_US_SLICES.ImageTextBlock]: ImageTextBlock,
  [CONTACT_US_SLICES.FooterBanner]: FooterBanner,
  [CONTACT_US_SLICES.Herov2]: Herov2,
  [CONTACT_US_SLICES.HeroFeaturesv2]: HeroFeaturesv2,
  [CONTACT_US_SLICES.HeroLogosv2]: HeroLogosv2,
  [CONTACT_US_SLICES.ImageTabs]: PrismicImageTabs,
};
